import React, { useEffect, useState } from 'react'

const TagListItem = (props) => {
  const [background, setBackground] = useState(null);
  const hover = !props.disableHover ? " hover:bg-primary-default hover:text-white transition-bg duration-100 hover:border-0" : (props.article ? " text-gray-400" : " text-primary-default");
  const pillBorder = props.article ? 'border border-gray-400 rounded-xl mr-2 p-1 px-5 cursor-pointer text-gray-400' : 'border-2 border-primary-default rounded-xl mr-2 p-1 px-5 cursor-pointer'

  useEffect(() => {
    if (props.selectedTag === props.tag) {
      setBackground(" bg-primary-default text-white");
    } else {
      setBackground(props.article ? " text-gray-400" : " text-primary-default");
    }
  }, [props.selectedTag]);

  return (
    <div className={"mb-2"}>
      <div onClick={() => props.onClick(props.tag)} className={pillBorder + background + hover}>
        <p className={"text-xs uppercase"}>{props.tag}</p>
      </div>
    </div>
  );
}

export default TagListItem;
