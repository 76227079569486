import React, { useEffect, useState } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import TagListItem from './TagListItem'
import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx';

const isBrowser = typeof window !== 'undefined';

const BlogListItem = (props) => {
  const flex = (!props.reverse ? "flex flex-col lg:flex-row" : "flex flex-col lg:flex-row-reverse");
  const imgRound = (!props.reverse ? "rounded-t-md lg:rounded-l-md lg:rounded-t-none w-full lg:w-1/2" : "rounded-t-md lg:rounded-r-md lg:rounded-t-none w-full lg:w-1/2");
  const tagsAlign = (!props.reverse ? "flex flex-row flex-wrap m-5 self-end" : "flex flex-row flex-wrap m-5 self-start");
  const url = props.slug ? '/blog' + props.slug.replace("/blog/posts", "") : '/blog';
  const finalUrl = url.replace("blog/ro/", "blog/");

  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  function handleWindowSizeChange() {
    setWidth(isBrowser ? window.innerWidth : 0);
  }

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
  }, []);

  const isMobile = width <= 768;

  return props.post ? (
    <Link
      to={finalUrl}
      className={"w-full"}
    >
      <article key={props.id} className={"w-full"}>
        <div className={"w-full my-5 lg:border-t-2 lg:border-b-2 border-gray-200 lg:border-r-2 rounded-md shadow-lg cursor-pointer hover:shadow-2xl transition-shadow duration-300"}>
          <div className={flex}>
            {props?.post?.cover?.childImageSharp?.gatsbyImageData ? <GatsbyImage
              image={props.post.cover.childImageSharp.gatsbyImageData}
              alt={props.post?.coverAlt}
              className={imgRound} /> : null }

            <div className={"flex flex-col w-full justify-between"}>
              <div className={"p-5 w-full"}>
                <div className={"flex flex-col"}>
                  <h2 className={"font-semibold text-center text-xl text-primary-default"}>{props.post.title}</h2>
                  <div className={"mt-5"}>
                    {isMobile ? <p className={"text-sm lg:text-md text-gray-600 font-normal mt-2"}>{props.excerpt}</p> : props?.listExcerpt?.map(excerpt => <p className={"text-sm lg:text-md text-gray-600 font-normal mt-2"}>{excerpt}</p>)}
                  </div>
                </div>
              </div>
              <div className={tagsAlign}>
                {props.post?.categories && props.post.categories.map((tag, index) => (
                  <TagListItem article={true} onClick={() => {}} tag={tag} selectedTag={null} key={index} disableHover />
                ))}
              </div>
            </div>
          </div>
        </div>
      </article>
    </Link>
  ) : null;
}

export default BlogListItem;
